// Client side "secrets". Should not be used for sensitive credentials
import {
  production,
  staging,
  development,
  test
} from '@/modules/config/credentials'
const envCredentials = {
  production, staging, development, test
}

const secrets = {
  facebookAppId: getFacebookAppId(),
  bugsnagApiKey: '364bea039de7fdc68538abf08c47a74a',
  generalBooxiApiKey: getBooxiApiKey(I18n, vueSupport),
  googleClientId: getGoogleClientId(),
  grecaptchaSiteKey: getGoogleRecaptchaKey(),
  olapicWidgetConfig: getOlapicWidgetConfig(),
  olapicGalleryConfig: getOlapicGalleryConfig(),
  splitIOAuthorizationKey: getSplitIOAuthorizationKey(),
  kakaotalkAppId: '',
  naverClientId: '',
  oktaWidgetConfig: getOktaWidgetConfig(),
  bvHostedUIconfig: getBvHostedUIconfig(),
  kameleoonSiteCode: 'r6pmdj58nt'
}

function envCredential (value) {
  return { ...envCredentials[vueSupport.env][value] }
}

function getFacebookAppId () {
  return envCredential('facebook')[I18n.siteCountryLs].appId
}

export function getBooxiApiKey (I18n, vueSupport) {
  const apiKeys = {
    nz: {
      production: '9C5p019z3ZB6y157jK81577BM42g6dV6',
      staging: '9C5p019z3ZB6y157jK81577BM42g6dV6',
      development: '9C5p019z3ZB6y157jK81577BM42g6dV6',
      test: '9C5p019z3ZB6y157jK81577BM42g6dV6'
    },
    au: {
      production: 'RRBYIctKGw77RkpN7ct9i747R5M2gXoV',
      staging: 'RRBYIctKGw77RkpN7ct9i747R5M2gXoV',
      development: 'RRBYIctKGw77RkpN7ct9i747R5M2gXoV',
      test: 'RRBYIctKGw77RkpN7ct9i747R5M2gXoV'
    }
  }

  const country = I18n.siteCountryLs === 'nz' ? 'nz' : 'au'
  const env = vueSupport.env
  return apiKeys[country][env]
}

function getGoogleClientId () {
  switch (vueSupport.env) {
    case 'production':
      return '186958557403-ejgihnt462u4jj2d4lb9ph3suqr1cg3o.apps.googleusercontent.com'
    case 'staging':
      return '517817051208-6iqh5vveolk7bboceb7mk4u2ufu54cb9.apps.googleusercontent.com'
    default:
      return '41333444509-55cs26d6tps9oems5tor0g4tpuo01mch.apps.googleusercontent.com'
  }
}

function getGoogleRecaptchaKey () {
  switch (vueSupport.env) {
    case 'production':
      return '6LfwDJAUAAAAAJ93Cvu-p7QlFkrGEU2Ehh3rGGtg'
    default:
      // this is a test site key for v2 invisible and will not work for v3 score based
      // to test v3 implementation on Vue, use '6LdMvZUnAAAAAInEasICe8e8QmZYXnL3JwMOeQ3P' instead
      return '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
  }
}

function getOlapicWidgetConfig () {
  const olapicWidgetConfig = {
    au: {
      apiKey: '4e940f58bb6a4c802ab9bce036aa5d8b28333d9fa921378f0cbb1ae59a2a013d',
      instanceId: 'de5c6673e30bf7bb0007d45018b7d06a'
    },
    hk: {
      apiKey: 'd2f66737cfd0cf80f18c4c86bb821001f197f1cbd3b8a5a83f1b3621bfd93e13',
      instanceId: '6cc676d7111183b96b703a580fc7eac9',
      lang: 'zh_HK'
    },
    id: {
      apiKey: '565e4b069802524314411dfbd2bbbf76beef839425d6556444a079c4813f9bf0',
      instanceId: 'a0d45dbbfc1a89d08c6de1f7a03b2f24',
      lang: 'id_ID'
    },
    kr: {
      apiKey: '8dd30f35938ce61ef972c0c1d589833fc33155a053fbb693888dcf7804e213c0',
      instanceId: 'a7a8a3d73d2c128b1694e7238ac4147b',
      lang: 'ko_KR'
    },
    my: {
      apiKey: '5b6f4cb825dcc35a28ff1becb31b71fb33b2731ebce00d029510b109ef2b119f',
      instanceId: '92d0e2ec036d8447798b7b48d9929493',
      lang: 'ms_MY'
    },
    nz: {
      apiKey: '5d6d8dc3f81356fca5bf4fdbe11f197ecfcae9b0037b1158039993a6d928d798',
      instanceId: 'afe03b277dc035ffad436061726a059e'
    },
    ph: {
      apiKey: '7befd237ff8cd626cb9e27458daeb354b01843a41afbd8a816c893e33061cd02',
      instanceId: 'ac6f00d1bb698a88f3a8c07fcbbd489b',
      lang: 'en_PH'
    },
    sg: {
      apiKey: '8667e3be8cda0451257d776bcdb790356f09691262b7e79218bcd56a9f85e65b',
      instanceId: 'a22241ba159cdee11029a17ac89dcdf6',
      lang: 'sg_SG'
    },
    th: {
      apiKey: 'c521fc53ad5f560b0360f2b4cdcccf443fcf0b6993098ce8fc8fcb5fb101f9a0',
      instanceId: '809e3af0bc87b91657606e9bcb584440',
      lang: 'th_TH'
    }
  }

  return olapicWidgetConfig[I18n.siteCountryLs]
}

function getOlapicGalleryConfig () {
  const olapicGalleryConfig = {
    au: {
      apiKey: '4e940f58bb6a4c802ab9bce036aa5d8b28333d9fa921378f0cbb1ae59a2a013d',
      instanceId: '837d2a34cd5c8d47ef9f8b11083877d6'
    },
    hk: {
      apiKey: 'd2f66737cfd0cf80f18c4c86bb821001f197f1cbd3b8a5a83f1b3621bfd93e13',
      instanceId: '5e8bb301481a6d96a0804197abf86691',
      lang: 'zh_HK'
    },
    id: {
      apiKey: '565e4b069802524314411dfbd2bbbf76beef839425d6556444a079c4813f9bf0',
      instanceId: '1df59ea4bf4c759676b75e6ea4b0b8f5',
      lang: 'id_ID'
    },
    kr: {
      apiKey: '8dd30f35938ce61ef972c0c1d589833fc33155a053fbb693888dcf7804e213c0',
      instanceId: '43297f80d652c583cb1c96053ea6652f',
      lang: 'ko_KR'
    },
    my: {
      apiKey: '5b6f4cb825dcc35a28ff1becb31b71fb33b2731ebce00d029510b109ef2b119f',
      instanceId: '570d6f1bc18990d23866ee674e6f8ed3',
      lang: 'ms_MY'
    },
    nz: {
      apiKey: '5d6d8dc3f81356fca5bf4fdbe11f197ecfcae9b0037b1158039993a6d928d798',
      instanceId: '7e7e5f2f75350343771cac778e283313'
    },
    ph: {
      apiKey: '7befd237ff8cd626cb9e27458daeb354b01843a41afbd8a816c893e33061cd02',
      instanceId: '1d3ed8bf8680b20197c0e40ab123a30b',
      lang: 'en_PH'
    },
    sg: {
      apiKey: '8667e3be8cda0451257d776bcdb790356f09691262b7e79218bcd56a9f85e65b',
      instanceId: 'becdb74c372f7772155f158276f53e4e',
      lang: 'sg_SG'
    },
    th: {
      apiKey: 'c521fc53ad5f560b0360f2b4cdcccf443fcf0b6993098ce8fc8fcb5fb101f9a0',
      instanceId: '90b09b98761cf4db64ca3be0594bb7cb',
      lang: 'th_TH'
    }
  }

  return olapicGalleryConfig[I18n.siteCountryLs]
}

function getSplitIOAuthorizationKey () {
  switch (vueSupport.env) {
    case 'production':
      return 'i8aqo6v91qnm4b9u6lcm7rfej7k9hgffm09q'
    case 'staging' :
      if (window.location.hostname.match(/(user-development-).*(.sephora-asia.dev)/g)) {
        return '8936a075g1hc62tbkdcj5orpehusaeul4hgv'
      } else if (window.location.hostname.match(/(se-development-).*(.sephora-asia.dev)/g)) {
        return 'g16heug9vbhi3q1a5jlel2a5ner68evpnd92'
      } else {
        return '6m58vhf5do3b4qpn4mfp1dsvs3sen6u1bnqv'
      }
    default:
      return 'localhost'
  }
}

function getOktaWidgetConfig () {
  switch (vueSupport.env) {
    case 'production':
      return {
        issuer: 'https://portal.sephora-asia.com/oauth2/default',
        clientId: '0oaaijsubwik4RDSD417'
      }
    default:
      return {
        issuer: 'https://sephoraasia-pprd.oktapreview.com/oauth2/default',
        clientId: '0oabqkqc0zfFrGpqS1d7'
      }
  }
}

// TODO: Update default clientName and siteId when available for dev environments.
function getBvHostedUIconfig () {
  switch (vueSupport.env) {
    case 'production':
      return {
        clientName: 'sephora-au',
        siteId: 'main_site'
      }
    default:
      return {
        clientName: 'sephora-au',
        siteId: 'main_site'
      }
  }
}

export default secrets
